import Vue from 'vue'

const LoadingButton = {
  name: 'loading-button',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    margin: {
      type: String,
      default: 'my-8'
    }
  },
  template: `
  <button class="button button--greenish text-white uppercase" :class="margin" @click.prevent="$emit('click')">
      <transition name="prev-fast" mode="out-in">
          <div v-if="loading" class="animated">
              <div class="sk-chase mx-auto">
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
              </div>
          </div>
          <span v-else class="animated"><slot></slot></span>
      </transition>
  </button>
  `
}
Vue.component('loading-button', LoadingButton)

export default LoadingButton
