import Vue from 'vue'

const InputComponent = {
  name: 'InputComponent',
  props: {
    value: {
      type: [String, Number],
      default: () => ('')
    },
    name: {
      type: String,
      default: 'default'
    },
    id: {
      type: String,
      default: 'default'
    },
    object: {
      type: Object,
      default: () => ({})
    },
    inputMode: {
      type: String,
      default: 'text'
    },
    maxLength: {
      type: [Boolean, String],
      default: false
    },
    capitalize: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    smallerHeight: {
      type: Boolean,
      default: false
    },
    icon: {
      type: [String, Boolean],
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    instantBlurError: {
      type: Boolean,
      default: true
    },
    checkout: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      focus: false
    }
  },
  mounted() {
    if(this.autofocus) {
      this.$refs.inputRef.focus()
    }
  },
  methods: {
    blur () {
      this.focus = false
      if (this.object.$touch) this.object.$touch()
      this.$emit('blur', { id: this.id, value: this.value })
    },
    setFocus () {
      this.focus = true
    }
  },
  template: `
   <div :class="{ 'checkout-error': object.$error }">
        <label
            class="block mb-2 text-14"
            :for="id">
          {{ this.name }}
        </label>
       <div class="flex flex-grow justify-center group">
           <div v-if="icon !== false"
                :class="{'border-red-53' : object.$error, 'border-gray-700': focus, 'border-primary-input': !focus }"
                class="animated group-hover:border-gray-700 focus:outline-none w-24 border flex items-center justify-center -mr-3 z-50 bg-white rounded-l-lg"
           >
               <img v-if="typeof icon === 'string'" :src="icon" alt="icon">
               <slot v-else name="icon"></slot>
           </div>
           <input
                   :value="value"
                   @input="$emit('input', $event.target.value)"
                   @blur="instantBlurError ? blur() : null"
                   @focus="setFocus()"
                   class="appearance-none animated border rounded-lg px-5 focus:outline-none w-full"
                   :class="{'border-red-52' : object.$error, 'shadow-error-input': object.$error && !checkout, 'capitalize': capitalize, 'h-18': smallerHeight === true, 'h-20': smallerHeight === false, 'rounded-sm group-hover:shadow-md focus:shadow-blue-shadow group-hover:border-blue-680 focus:border-blue-680' : checkout, 'border-gray-500 group-hover:border-gray-700 focus:shadow-focus-input' : !checkout}"
                   :type="type"
                   :placeholder="placeholder"
                   :id="id"
                   :maxlength="maxLength"
                   :inputmode="inputMode"
                   ref="inputRef">
       </div>
  </div>
`
}

Vue.component('InputComponent', InputComponent)

export default InputComponent
