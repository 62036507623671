import Vue from 'vue'
import Noty from 'noty'
import InputComponent from './components/InputComponent'
import LoadingButton from './components/LoadingButton'
import { email, required, minLength, sameAs } from 'vuelidate/lib/validators'
import { Vuelidate } from 'vuelidate'
import axios from 'axios'
import { API, messages } from './constants'
import { getParamData } from './utils/links'
import { captureException } from './utils/sentry'
Vue.config.errorHandler = (err, vm, info) => {
  captureException(err)
}
Vue.use(Vuelidate)

if (document.getElementById('login')) {
  const initialState = {
    username: '',
    password: '',
    token: window.csrf
  }
  // eslint-disable-next-line no-new
  new Vue({
    el: '#login',
    components: {
      'input-component': InputComponent,
      'loading-button': LoadingButton
    },
    data () {
      return {
        form: { ...initialState },
        loading: false,
        errorMessage: ''
      }
    },
    watch: {
      'form.username': {
        handler (newVal, oldVal) {
          if (newVal !== oldVal && this.errorMessage !== '') this.errorMessage = ''
        }
      }
    },
    validations: {
      form: {
        username: {
          required,
          email
        },
        password: {
          required
        }
      }
    },
    methods: {
      submit (path = '#') {
        this.$v.form.$touch()
        if (this.$v.form.$invalid === false) {
          this.loading = true
          const data = {
            ...this.form,
            username: this.form?.username?.toLowerCase(),
            domain: window.domain
          }
          axios.post(API.LOGIN, data)
            .then(res => {
              this.$v.form.$reset()
              this.form = initialState
              this.loading = false
              window.location.href = path
            })
            .catch(ex => {
              this.errorMessage = ex?.response?.data?.message || messages.errors.requestFailed
              this.loading = false
            })
        }
      }
    }
  })
}

if (document.getElementById('resetPassword')) {
  const initialState = {
    email: '',
    token: '',
    domain: window.domain
  }
  // eslint-disable-next-line no-new
  new Vue({
    el: '#resetPassword',
    components: {
      'input-input': InputComponent,
      'loading-button': LoadingButton
    },
    data () {
      return {
        form: { ...initialState },
        loading: false,
        sent: false,
        errorMessage: ''
      }
    },
    validations: {
      form: {
        email: {
          required,
          email
        }
      }
    },
    watch: {
      'form.email': {
        handler (newVal, oldVal) {
          if (newVal !== oldVal && this.errorMessage !== '') this.errorMessage = ''
        }
      }
    },
    methods: {
      submit () {
        this.$v.form.$touch()
        if (this.$v.form.$invalid === false) {
          this.form.token = window.csrf
          this.form.domain = window.domain
          this.loading = true
          axios.post(API.USER_PASSWORD, this.form)
            .then(res => {
              this.$v.form.$reset()
              this.form = initialState
              this.loading = false
              this.sent = true
            })
            .catch(ex => {
              this.errorMessage = ex?.response?.data?.message || messages.errors.serverError
              this.loading = false
            })
        }
      }
    }
  })
}

if (document.getElementById('cancelMembership')) {
  const initialState = {
    email: '',
    token: ''
  }
  // eslint-disable-next-line no-new
  new Vue({
    el: '#cancelMembership',
    components: {
      'input-input': InputComponent,
      'loading-button': LoadingButton
    },
    data () {
      return {
        form: { ...initialState },
        loading: false
      }
    },
    methods: {
      submit (link = '/') {
        this.$v.form.$touch()
        if (this.$v.form.$invalid === false) {
          this.form.token = window.csrf
          this.form.domain = window.domain
          this.loading = true
          axios.post(API.CANCEL_MEMBERSHIP, this.form)
            .then(res => {
              this.$v.form.$reset()
              this.form = initialState
              this.loading = false
              window.location.href = link
            })
            .catch(ex => {
              new Noty({
                text: messages.errors.notRegistered,
                timeout: 5000,
                type: 'error'
              }).show()
              console.log(ex)
              this.loading = false
            })
        }
      }
    },
    validations: {
      form: {
        email: {
          required,
          email
        }
      }
    }
  })
}

if (document.getElementById('changePassword')) {
  // eslint-disable-next-line no-new
  new Vue({
    el: '#changePassword',
    components: {
      'input-component': InputComponent,
      'loading-button': LoadingButton
    },
    data () {
      return {
        password: '',
        repeat: '',
        loading: false,
        errorMessage: ''
      }
    },
    watch: {
      password (newVal, oldVal) {
        if (newVal !== oldVal && this.errorMessage !== '') this.errorMessage = ''
      },
      repeat (newVal, oldVal) {
        if (newVal !== oldVal && this.errorMessage !== '') this.errorMessage = ''
      }
    },
    validations: {
      password: {
        required,
        minLength: minLength(6)
      },
      repeat: {
        required,
        sameAs: sameAs('password')
      }
    },
    methods: {
      submit (link) {
        this.$v.$touch()
        if (this.$v.password.$invalid === false && this.$v.repeat.$invalid === false) {
          this.loading = true
          const data = {
            password: this.password,
            passwordToken: getParamData().password_token,
            token: window.csrf
          }
          axios.put(API.USER_PASSWORD, data)
            .then(res => {
              window.location.href = link
              this.$v.$reset()
              this.password = ''
              this.repeat = ''
              this.loading = false
            })
            .catch(ex => {
              this.errorMessage = ex?.response?.data?.message || messages.errors.serverError
              this.loading = false
            })
        }
      }
    }
  })
}

if(document.getElementById('resend')) {
  // eslint-disable-next-line no-new
  new Vue({
    el: '#resend',
    delimiters: ['[[', ']]'],
    components: {
      'input-component': InputComponent,
      'loading-button': LoadingButton
    },
    data() {
      return {
        form: {
          email: ''
        },
        errorMessage: '',
        showSuccess: false,
        loading: false
      }
    },
    watch: {
      'form.email': {
        handler(newValue, oldValue) {
          if (newValue !== oldValue) {
            this.showSuccess = false
            this.errorMessage = ''
          }
        }
      }
    },
    methods: {
      submit() {
        this.$v.$touch()
        if (this.$v.form.email.$invalid === false && this.loading === false) {
          this.loading = true
          const { email } = this.form
          axios.post(API.RESEND_PASSWORD, { email, domain: window.domain, token: window.csrf })
            .then(() => {
              this.loading = false
              this.showSuccess = true
              this.email = ''
              this.$v.$reset()
            })
            .catch(ex => {
              if (ex && ex.response) {
                if (ex.response.status >= 500) {
                  this.errorMessage = messages.errors.serverError
                  captureException(ex)
                } else {
                  this.errorMessage = ex.response.data.message ? ex.response.data.message : ''
                }
              }
              this.loading = false
            })
        }
      }
    },
    validations: {
      form: {
        email: {
          required,
          email
        }
      }
    }
  })
}
